import * as React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import About from "../../components/Edit/interfaces/About"
import '../../styles/fonts.css'

const EditPage = () => {
  return <About />
}

export default EditPage

export const Head = () => <title>Verse Estate Corporate - About Us</title>
